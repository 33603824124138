import React, { useState } from "react";
import Profile from "../Images/mypicture.jpg";
import cv from "../Images/CV-Youness-EL-MODAHHAB.pdf";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArticleIcon from "@mui/icons-material/Article";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-scroll";

export default function Home() {
  const [isHovering, setIsHovering] = useState(false);

  const [isHoveringin, setIsHoveringin] = useState("");

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <div name="Home" className="bg-[#1A1A29] h-screen flex items-center">
        <div className="w-[100%] flex justify-between my-auto items-center">
          <div className="sm:w-[45%] text-white">
            <div className="w-[80%] mx-auto">
              <p className="text-3xl font-mono">Hello, i'm</p>
              <h1 className=" lg:text-7xl  md:text-7xl text-5xl my-8 font-serif">
                Youness EL <br /> MODAHHAB
              </h1>
              <p className="text-base font-mono">
                Freelance Front-End Web Developer & Fullstack developer,
                specializing in building exceptional digital experiences.
                Currently, I’m focusing on building responsive front-end web
                applications while learning back-end technologies.
              </p>
            </div>
            <div className="text-center flex lg:flex-row md:flex-row flex-col justify-evenly items-center h-48">
              <a href={cv} target="_blank">
                {" "}
                <button className="bg-[#7562E0] border-none rounded  p-2 w-60">
                  Rusume
                  <ArticleIcon className="ml-5" />{" "}
                </button>{" "}
              </a>
              <Link smooth={true} duration={300} to="About">
                <button
                  onMouseEnter={() => setIsHoveringin("About")}
                  onMouseLeave={() => setIsHoveringin("")}
                  className="border-2 border-[#7562E0] rounded p-2 w-60 hover:opacity-80 relative" // Add "relative" class
                >
                  About Me
                  <ArrowForwardIcon
                    className={`ml-5 transition-transform duration-300 transform ${
                      isHoveringin === "About" ? "rotate-90" : ""
                    }`}
                  />
                </button>
              </Link>
            </div>
          </div>
          <div className="w-[45%] lg:p-20 md:p-32 lg:block md:block hidden sm:p-0">
            <div
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              className="relative rounded-[25%] overflow-hidden"
            >
              <img className="" src={Profile} alt="WorkOne" />
              {isHovering && (
                <div className=" absolute bg-white opacity-80 w-full h-full top-0 flex flex-col justify-center items-center">
                  <h1 className="font-bold mb-8 text-4xl">Go check !</h1>
                  <div className="flex justify-between w-[50%] text-5xl">
                    <a
                      href="https://www.linkedin.com/in/youness-el-modahhab-6463b322a/"
                      title="LinkedIn"
                      target="_blank"
                    >
                      <LinkedInIcon fontSize="2rem" />
                    </a>

                    <a
                      href="https://github.com/Youness-ELMO"
                      title="GitHub"
                      target="_blank"
                    >
                      <GitHubIcon fontSize="2rem" />
                    </a>
                    <a href="mailto:younesselmodahhab@gmail.com" title="Gmail">
                      <EmailIcon fontSize="2rem" />
                    </a>
                    <a
                      href="https://wa.me/36705517315?"
                      title="WhatsApp"
                      target="_blank"
                    >
                      <WhatsAppIcon fontSize="2rem" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
