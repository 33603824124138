import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";


export default function Footer() {
    const current = new Date();
  return (
    <>
      <footer className="bg-[#181824] text-center text-white pt-6">
        <div>
          <div className="mb-6 flex justify-center">
            <a href="mailto:younesselmodahhab@gmail.com" title="Gmail">
              <EmailIcon
                fontSize="6rem"
                className="text-5xl rounded-full border-2 border-white p-2"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/youness-el-modahhab-6463b322a/"
              title="LinkedIn"
            >
              <LinkedInIcon
                className="text-5xl rounded-full border-2 border-white mx-2	p-2 "
                fontSize="6rem"
              />
            </a>

            <a href="https://github.com/Youness-ELMO" title="GitHub">
              <GitHubIcon
                className="text-5xl rounded-full border-2 border-white p-2 "
                fontSize="6rem"
              />
            </a>
          </div>
        </div>

        <div className="p-4 text-center">
          © {current.getFullYear()} | All Rights Reserved.
        </div>
      </footer>
    </>
  );
}
