import React from "react";
import { Link } from "react-scroll"
import { useState, useEffect } from "react";
import DevAc from "../Images/undraw_developer_activity_re_39tg.svg"

export default function About() {
 

  return (
    <>
      <div
        className="bg-[#1A1A29] lg:h-screen md:h-screen flex flex-col p-12 pt-24"
        name="About"
      >
        <h1 className="px-4 underline font-mono text-center text-2xl lg:text-5xl text-shadow mb-4 ">
          About Me
        </h1>
        <div className=" flex justify-between w-full items-center h-full">
          <p className="lg:w-[45%] md:w-[45%] lg:p-12 md:p-12 text-white lg:font-mono  text-justify	">
            As a software developer, I have honed my skills in both frontend and
            backend technologies, crafting solutions that merge innovation with
            functionality. I am proficient in JAVA, mobile application
            development, and various programming languages, equipping me with
            the tools to tackle diverse challenges in software development. My
            journey in software development has seen me navigate roles as a
            software tester and full-stack developer, where I've leveraged my
            expertise to contribute to the creation of impactful software
            solutions. Collaborating with teams, managing projects, and ensuring
            efficient workflows are integral parts of who I am. With a
            meticulous eye for detail and a passion for problem-solving, I
            thrive on the intricacies of software development, continuously
            seeking opportunities to learn and grow. My commitment to delivering
            top-notch solutions underscores my dedication to excellence in every
            aspect of my work.
          </p>
          <img
            className="w-[45%] p-16 lg:block md:block hidden"
            src={DevAc}
          ></img>
        </div>
      </div>

      {/* <section className="bg-black">
        <h1 class="text-shadow"> -- Text Shadow -- </h1>
        <div class="tags">
          <p class="text-shadow">
            <small>#codepenchallenge #cpc-text-shadow</small>
          </p>
        </div>
      </section> */}
    </>
  );
}
