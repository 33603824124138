//Skills
import Ado from "../asset/Skills/Ado.net-framework.svg";
import Bootstrap from "../asset/Skills/bootstrap.svg";
import Csharp from "../asset/Skills/csharp.svg";
import Css from "../asset/Skills/css.svg";
import Git from "../asset/Skills/git.svg";
import Html from "../asset/Skills/html5.svg";
import JS from "../asset/Skills/javascript.svg";
import materialui from "../asset/Skills/material-ui.svg";
import Mongodb from "../asset/Skills/mongodb.svg";
import mysql from "../asset/Skills/mysql.svg";
import Nodejs from "../asset/Skills/nodejs.svg";
import Oracle from "../asset/Skills/Oracle.svg";
import Python from "../asset/Skills/python.svg";
import Reactjs from "../asset/Skills/reactjs.svg";
import Sqlserver from "../asset/Skills/sql-server.svg";
import Tailwind from "../asset/Skills/tailwind-svgrepo-com.svg";
import Figma from "../asset/Skills/figma.svg";

//certificates
import Excel from "../asset/Certificates/Office Excel.jpg";
import funMooc from "../asset/Certificates/python.jpg";
import Cisco from "../asset/Certificates/python cisco.jpg";
import SQLSololearn from "../asset/Certificates/Sololearn-SQL.jpg";
import SQLHackerRank from "../asset/Certificates/SQL hackerRank.jpg";
import SQLLInkedin from "../asset/Certificates/SQL Essentieal Training.jpn.png";
import ITLinkedin from "../asset/Certificates/IT for Busness Success.jpn.png";
import ReactSoftware from "../asset/Certificates/React Software Architecture.png";
import ReactBuilding from "../asset/Certificates/React.js Building an Interface.png";
import ReactEssential from "../asset/Certificates/React.js Essential Training.png";
import GitHubCoursera from "../asset/Certificates/Introduction to Git and GitHub.png";
import HTMLandCSSindepth from "../asset/Certificates/HTML and CSS in depth.png";

import Amine from "../Images/Amine.png";
import Imane from "../Images/imane.png";
import Pedro from "../Images/pedro.png";
import Gabriel from "../Images/Gabriel.png";
import Youssef from "../Images/Youssef.png";

export const skills = [
  {
    id: 1,
    img: Html,
    title: "HTML",
  },
  {
    id: 2,
    img: Css,
    title: "CSS",
  },
  {
    id: 3,
    img: JS,
    title: "JavaScript",
  },
  {
    id: 4,
    img: Reactjs,
    title: "React JS",
  },
  {
    id: 5,
    img: materialui,
    title: "Material-ui",
  },
  {
    id: 6,
    img: Tailwind,
    title: "Tailwind",
  },
  {
    id: 7,
    img: Bootstrap,
    title: "Bootstrap",
  },
  {
    id: 8,
    img: Nodejs,
    title: "Node JS",
  },
  {
    id: 9,
    img: Mongodb,
    title: "MongoDB",
  },

  {
    id: 10,
    img: Python,
    title: "Python",
  },
  {
    id: 11,
    img: Csharp,
    title: "C#",
  },
  {
    id: 12,
    img: Ado,
    title: "Ado.Net",
  },
  {
    id: 13,
    img: Sqlserver,
    title: "SQL server",
  },
  {
    id: 14,
    img: mysql,
    title: "MySQL",
  },
  {
    id: 15,
    img: Oracle,
    title: "Oracle",
  },
  {
    id: 16,
    img: Git,
    title: "Git",
  },
  {
    id: 17,
    img: Figma,
    title: "Figma",
  },
];

//certificates

export const Certif = [
  {
    id: 1,
    img: Excel,
    title: "Microsoft Office Specialist for Office Excel® 2016",
    link: "https://drive.google.com/file/d/1DSejXfZDNi_ogOIjY4M6Dlmjv5bwgDVI/view",
  },
  {
    id: 2,
    img: funMooc,
    title: "Learn to code with Python offered by ULB",
    link: "https://lms.fun-mooc.fr/media/attestations/attestation_suivi_course-v1:ulb+44013+session04_b74c48b527639f5c2ad7f4caf12bab4b.pdf",
  },
  {
    id: 3,
    img: Cisco,
    title: "Programming Essentials in Python from Cisco",
    link: "https://drive.google.com/file/d/1JjZawuD5OIXRj3ex3vvdW4Uob1qdQCZo/view",
  },
  {
    id: 4,
    img: SQLHackerRank,
    title: "SQL (Basic) Certificate from HackerRank",
    link: "https://www.hackerrank.com/certificates/38170179b0d7",
  },
  {
    id: 5,
    img: SQLSololearn,
    title: "Certified SQL from Sololearn",
    link: "https://www.sololearn.com/certificates/CT-E67PLX2R",
  },
  {
    id: 6,
    img: SQLLInkedin,
    title: "SQL Essential Training",
    link: "https://www.linkedin.com/learning/certificates/f113fbb43bdaa484c9bf533125f5ef7b8142834e7c8eb32635c025bfe0adf864?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BdRgJ2FfUS4SFbwHXDZ5B6Q%3D%3D",
  },
  {
    id: 7,
    img: ITLinkedin,
    title: "IT for Business Success",
    link: "https://www.life-global.org/certificate/dba395fe-9db2-42e6-ad03-4b171151fbcb",
  },
  {
    id: 8,
    img: ReactSoftware,
    title: "React: Software Architecture",
    link: "https://www.linkedin.com/learning/certificates/07dbed4ab93b7b39b9766d08ad03fc447d44d0e8bd80eb582eeffcc4d8fb1c6e?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BdRgJ2FfUS4SFbwHXDZ5B6Q%3D%3D",
  },
  {
    id: 9,
    img: ReactEssential,
    title: "React.js: Essential Training",
    link: "https://www.linkedin.com/learning/certificates/b8fb18e04c1d3eff7b63fbe680684470be8226b94237119ea32020dd48051c04?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BdRgJ2FfUS4SFbwHXDZ5B6Q%3D%3D",
  },
  {
    id: 10,
    img: ReactBuilding,
    title: "React.js: Building an Interface",
    link: "https://www.linkedin.com/learning/certificates/4d7e33b3170ee6a0cfebd1b03791076217e61124e9ae36b2fdb94ff407d5e89c?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BdRgJ2FfUS4SFbwHXDZ5B6Q%3D%3D",
  },
  {
    id: 11,
    img: GitHubCoursera,
    title: "Introduction to Git and GitHub",
    link: "https://coursera.org/share/7a0a85ec81460fc6deacf43d6fb8e384",
  },
  {
    id: 12,
    img: HTMLandCSSindepth,
    title: "HTML and CSS in depth",
    link: "https://coursera.org/share/6b81ff744b72b2aefee88014dbf5bce6",
  },
];

//Timonials
export const Timonials = [
  {
    id: 1,
    image: Gabriel,
    name: "Gabriel Prates",
    text: "Youness is a person who pays attention to details. His eyes and interest about frontend are great allies to him. He's a great learner and is capable of asking the right questions that guide him to the next steps. It was a pleasure working with him and seeing him trying to help others. He's a nice team mate.",
  },
  {
    id: 2,
    image: Pedro,
    name: "Pedro Amaral",
    text: "I worked with Youness for 6 months and I think highly of him. During this period I was able to witness his desire to learn and dedication to the things assigned to him, and in addition to this, he always demonstrated availability and willingness to help others on the team. In addition to showing good technical knowledge in the area of test automation and bug resolution, both back-end and front-end.",
  },
  {
    id: 3,
    image: Youssef,
    name: "Youssef Boumandil",
    text: "All i can say about Younes is that he's all you can ask for in a co-worker, he's talented hard worker and excellent at managing his tasks when he's under pressure he's also caring for his team mates what makes him a great person, i was honored working with such a great guy and i hope we could be co-workers again",
  },
  {
    id: 4,
    image: Amine,
    name: "Amine Hayad",
    text: "Youness is a very hard-working employee who can tackle any development problem with ease. We have worked together on LOGYTalks and I found him a highly skilled and dedicated professional. His expertise in the field of IT has helped our company immensely. He’s also an all-around great guy to work with! I highly recommend him to anyone looking for help on their team or IT projects.",
  },
  {
    id: 5,
    image: Imane,
    name: "Imane Ailal",
    text: "Youness have always done an excellent job in developing websites and thus finding new solutions. He has the most extensive and diverse knowledge of software and programming, and others even sought him out for advice. A reliable and highly capable developer, I can’t recommend him highly enough.",
  },
];
