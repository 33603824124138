import React from "react";
// import { useState, useEffect } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import emailjs from "emailjs-com";
import EmailIcon from "@mui/icons-material/Email";

export default function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rvt6okf",
        "template_f3eabsf",
        e.target,
        "S_j0rzmDshPvi3Q1D"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div
        class="flex justify-center flex-col items-center w-auto h-full bg-[#1A1A29] overflow-hidden lg:pt-24"
        name="Contact"
      >
        <h1 className=" text-4xl underline font-mono text-center lg:text-5xl text-shadow mb-28">
          Contact
        </h1>
        <form onSubmit={sendEmail}>
          <div class="container mx-auto my-4 px-4 lg:px-20">
            <div
              class="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl "
              style={{ boxShadow: "#ffffff  4px 2px 50px" }}
            >
              <div class="flex">
                <h1 class="text-white font-bold uppercase text-5xl">
                  Send me a <br /> message
                </h1>
              </div>
              <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <input
                  name="first_name"
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-white"
                  type="text"
                  placeholder="First Name*"
                  required
                />
                <input
                  name="last_name"
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-white"
                  type="text"
                  placeholder="Last Name*"
                  required
                />
                <input
                  name="email"
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-white"
                  type="email"
                  placeholder="Email*"
                  required
                />
                <input
                  name="num_tel"
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-white"
                  type="number"
                  placeholder="Phone"
                />
              </div>
              <div class="my-4">
                <textarea
                  name="message"
                  required
                  placeholder="Message*"
                  class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline shadow-white"
                ></textarea>
              </div>
              <div class="my-2 w-1/2 lg:w-1/4">
                <button
                  class="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline shadow-white transition ease-in-out delay-150 hover:-translate-y-1 lg:hover:scale-110 lg:hover:bg-cyan-500 duration-300"
                  type="submit"
                >
                  Send Message
                </button>
              </div>
            </div>

            <div class="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-blue-900 rounded-2xl">
              <div class="flex flex-col text-white">
                <h1 class="font-bold uppercase text-4xl my-4">Let's meet !</h1>
                <p class="text-gray-400">
                  Looking for a reliable partner to bring your ideas to life?
                  Whether you have a suggestion, project, or simply want to say
                  hello, don't hesitate to fill out the form. As an
                  experienced software developer, I'm here to collaborate and
                  create tailored solutions for you. Expect prompt replies!
                </p>

                <div class="flex my-4 w-2/3 lg:w-1/2">
                  <div class="flex flex-col">
                    <i class="fas fa-map-marker-alt pt-2 pr-2" />
                  </div>
                  <div class="flex flex-col">
                    <h2 class="text-2xl">My Location</h2>
                    <p class="text-gray-400">Debrecen, Hungary</p>
                  </div>
                </div>

                <div class="flex my-4 w-2/3 lg:w-1/2">
                  <div class="flex flex-col">
                    <i class="fas fa-phone-alt pt-2 pr-2" />
                  </div>
                  <div class="flex flex-col">
                    <h2 class="text-2xl ">Contact Me</h2>
                    <a
                      href="mailto:younesselmodahhab@gmail.com"
                      class="text-gray-400 flex my-2"
                    >
                      {" "}
                      <EmailIcon className="mr-2 text-white" />{" "}
                      Younesselmodahhab@gmail.com
                    </a>
                    <a href="tel:+212606147808" class="text-gray-400 flex ">
                      {" "}
                      <LocalPhoneIcon className="mr-2 text-white" /> +36 70 551 7315
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
