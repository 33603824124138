import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Pages/Navbar";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Skills from "./Pages/Skils";
import Certificates from "./Pages/Certificates";
import Footer from "./Pages/Footer";
import Testimonials from "./Pages/Testimonials";
import Loading from "./Pages/Loading";

function App() {
const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data) that takes time
    const fakeAsyncOperation = () => {
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust the timeout as needed
    };

    fakeAsyncOperation();
  }, []);
  return (
    <>
      {/* {loading ? (
        <Loading />
      ) : ( */}
        <div>
          <NavBar />
          <Home />
          <About />
          <Skills />
          <Certificates />
          <Testimonials />
          <Contact />
          <Footer />
        </div>
      {/* )} */}

      {/* <Loading /> */}
    </>
  );
}

export default App;
