import React, { useContext, useRef, useState } from "react";

import Slider from "react-slick";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import { Timonials } from "../data/Info";

function Testimonials() {
  const sliderRef = useRef();

  var settings = {
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    speed: 800,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 3,
    loop: false,
    autoplaySpeed: 3000,
    draggable: true,
    swipeToSlide: true,
    swipe: true,
  };
  const [name, setName] = useState();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      {/* {Timonials.length > 0 && (
        <div className="testimonials" style={{ backgroundColor: "#f56539" }}>
          <div className="testimonials--header">
            <h1 style={{ color: "#eaeaea" }}>Testimonials</h1>
          </div>
          <div className="testimonials--body">
            <FaQuoteLeft className="quote" style={{ color: "#eaeaea" }} />
            <div
              className="testimonials--slider"
              style={{ backgroundColor: "#f56539" }}
            >
             
              <button
                className="prevBtn"
                onClick={gotoPrev}
                style={{ backgroundColor: "#eaeaea" }}
              >
                <FaArrowLeft
                  style={{ color: "#f56539" }}
                  aria-label="Previous testimonial"
                />
              </button>
              <button
                className="nextBtn"
                onClick={gotoNext}
                style={{ backgroundColor: "#eaeaea" }}
              >
                <FaArrowRight
                  style={{ color: "#f56539" }}
                  aria-label="Next testimonial"
                />
              </button>
            </div>
          </div>
        </div>
      )} */}
      {/* <Slider {...settings} ref={sliderRef}>
        {Timonials.map((test) => (
          <div className="" key={test.id}>
            <p>{test.text}</p>
            <h1>{test.name}</h1>
          </div>
        ))}
      </Slider> */}
      {/* <div className="  h-screen flex flex-col lg:p-12 md:p-12 "> */}
      {/*  */}
      <div
        className="bg-[#1A1A29] h-screen flex flex-col items-center justify-center overflow-hidden relative"
        name="Testimonials"
      >
        <h1 className="px-4 underline font-mono text-center text-2xl lg:text-5xl text-shadow mb-10">
          Testimonials
        </h1>
        <Slider {...settings} ref={sliderRef} className="flex App w-[80%]">
          {Timonials.map((test) => (
            <div className="w-full flex flex-col items-center justify-center">
              <img
                className="image-testimon lg:translate-x-[53%] translate-y-[52%] lg:mx-0 mx-auto"
                // className=" mx-auto my-0 rounded-full translate-y-[50%] z-10 w-24 h-24 flex items-center justify-center"
                src={test.image}
                alt={test.name}
              />
              <div className=" styletest bg-[#19376D] max-w-7xl ">
                <h1 className="text-2xl font-semibold text-center text-[#A5D7E8]">
                  {test.name}
                </h1>
                <p className="italic font-light lg:w-4/5 md:w-4/5 sm:w-52 lg:text-[18px] md:text-[18px] text-[11px] text-white sm:text-ellipsis lg:text-justify m-auto px-4">
                  {test.text}
                </p>
              </div>
            </div>
          ))}
        </Slider>
        <button
          className="w-16 h-16 lg:flex md:flex items-center justify-center text-2xl bg-white rounded-full absolute left-24 hidden"
          onClick={gotoPrev}
        >
          <FaArrowLeft aria-label="Previous testimonial" />
        </button>
        <button
          className="w-16 h-16 lg:flex md:flex items-center justify-center text-2xl bg-white rounded-full absolute right-24 hidden"
          onClick={gotoNext}
        >
          <FaArrowRight aria-label="Next testimonial" />
        </button>
      </div>
      {/* </div> */}
    </>
  );
}

export default Testimonials;
