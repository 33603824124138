import React, { useRef, useState } from "react";
import { Certif } from "../data/Info";

import Modal from "@mui/material/Modal";

const style = {};

export default function Certificates() {
  const imgID = useRef(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (index) => {
    imgID.current = index;
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <div
        className="bg-[#1A1A29] h-full flex flex-col lg:p-12 md:p-8 p-6 lg:pt-24"
        name="Certificate"
      >
        <h1 className="px-4 underline font-mono text-center text-3xl lg:text-5xl text-shadow">
          Certificates
        </h1>
        <div class="cccontainerrr">
          {Certif.map((index, num) => (
            <>
              <div
                key={index.id}
                style={{
                  background: "rgba(255, 255, 255, 0.05)",
                  boxShadow: "0 15px 35px rgba(0, 0, 0, 0.5)",
                  backdropFilter: "blur(10px)",
                }}
                class="card relative w-[300px] h-[400px] m-5 rounded-2xl flex justify-center items-center "
              >
                <div class="content">
                  <div className="relative w-56 h-48 overflow-hidden">
                    <img src={index.img} />
                  </div>
                  <div class="contentBx">
                    <span>{index.title}</span>
                  </div>
                </div>
                <ul className="sci absolute bottom-12 flex ">
                  <li
                    key={index.id}
                    className="my-0 mx-3 opacity-0 list-none translate-y-10 duration-500"
                  >
                    <button
                      onClick={() => {
                        handleOpen(index);
                      }}
                      className="text-white"
                    >
                      View
                    </button>
                  </li>

                  <li className="my-0 mx-3 opacity-0 list-none translate-y-10 duration-500">
                    <a href={index.link} className="text-white">
                      check
                    </a>
                  </li>
                </ul>
              </div>
            </>
          ))}
          <Modal open={open} onClose={handleClose}>
            <div
              className="w-[80%] lg:w-auto"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img
                key={imgID.current.id}
                src={imgID.current.img}
                alt={imgID.current.id}
              />
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
