import React from "react";
//import { Link } from "react-scroll";
import { skills } from "../data/Info";
import Marquee from "react-fast-marquee";

export default function Skills() {
  return (
    <>
      {/* <div className=""> */}
      <div
        className="  bg-[#1A1A29] h-screen flex flex-col items-center justify-evenly overflow-hidden"
        name="Skills"
      >
        <h1 className="px-4 underline font-mono text-center text-4xl lg:text-5xl text-shadow ">
          Skills
        </h1>
        <Marquee
          gradient={false}
          speed={80}
          pauseOnHover={true}
          pauseOnClick={true}
          delay={0}
          play={true}
          direction="left"
        >
          {skills.map((index, id) => (
            <div
              style={{ boxShadow: "0 0 15px white" }}
              className=" lg:hover:scale-[1.15] cursor-pointer bg-gray-100 w-40 h-40 m-6 flex flex-col items-center justify-center rounded-lg py-8 px-4 ease-in-out duration-300 "
              key={id}
            >
              <img
                className=" h-11 lg:h-14"
                src={index.img}
                alt={index.title}
              />
              <h3 className="not-italic font-medium text-xl  text-center mt-4">
                {index.title}
              </h3>
            </div>
          ))}
        </Marquee>
      </div>
      {/* </div> */}
    </>
  );
}
