import React from "react";
import { Link } from "react-scroll";
import { useState, useEffect } from "react";
import Menu from "../asset/Projects/OpenMenu.png";
import Close from "../asset/Projects/CloseMenu.png";
import "../CSS/Style.css";
import Profile from "../Images/mypicture.jpg";

export default function NavBar() {
  const [toggle, setToggle] = useState(false);
  const [showProfileImage, setShowProfileImage] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowProfileImage(true);
    } else {
      setShowProfileImage(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const onSetActiveMenuItem = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <>
      <nav className="z-50 p-2 items-center flex justify-between lg:justify-evenly bg-[#181824] fixed w-full lg:h-16">
        <div className="flex items-center	 sm:text-2xl text-sm cursor-pointer text-white font-bold ">
          {showProfileImage && (
            <img
              className={`lg:w-10 md:w-10 w-8 rounded-full border-white border-2 mr-4 ${
                showProfileImage ? "fadeIn" : ""
              }`}
              src={Profile}
              alt="WorkOne"
            />
          )}
          EL MODAHHAB Youness
        </div>
        <div className=" lg:justify-evenly justify-around sm:flex w-[50%] lg:w-[40%] hidden">
          <ul className="navLink text-white flex m-2 cursor-pointer">
            <li className="mx-4 my-6 md:my-0">
              <Link
                smooth={true}
                duration={300}
                to="Home"
                onClick={() => onSetActiveMenuItem("item1")}
                className={`font-medium ${
                  activeItem === "item1" ? "text-cyan-500" : ""
                } hover:scale-125`}
              >
                Home
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link
                smooth={true}
                duration={300}
                to="About"
                onClick={() => onSetActiveMenuItem("item2")}
                className={`font-medium ${
                  activeItem === "item2" ? "text-cyan-500" : ""
                } hover:scale-125`}
              >
                About
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link
                smooth={true}
                duration={300}
                to="Skills"
                onClick={() => onSetActiveMenuItem("item3")}
                className={`font-medium ${
                  activeItem === "item3" ? "text-cyan-500" : ""
                } hover:scale-125`}
              >
                Skills
              </Link>
            </li>
            {/* <li className="mx-4 my-6 md:my-0">
              <Link
                smooth={true}
                duration={300}
                to="Projects"
                onClick={() => onSetActiveMenuItem("item4")}
                className={`font-medium ${
                  activeItem === "item4" ? "text-cyan-500" : ""
                } hover:scale-125`}
              >
                Projects
              </Link>
            </li> */}

            <li className="mx-4 my-6 md:my-0">
              <Link
                smooth={true}
                duration={300}
                to="Certificate"
                onClick={() => onSetActiveMenuItem("item5")}
                className={`font-medium ${
                  activeItem === "item5" ? "text-cyan-500" : ""
                } hover:scale-125`}
              >
                Certificate
              </Link>
            </li>
            <li className="mx-4 my-6 md:my-0">
              <Link
                smooth={true}
                duration={300}
                to="Testimonials"
                onClick={() => onSetActiveMenuItem("item6")}
                className={`font-medium ${
                  activeItem === "item6" ? "text-cyan-500" : ""
                } hover:scale-125`}
              >
                Testimonials
              </Link>
            </li>
          </ul>

          <Link
            smooth={true}
            duration={300}
            to="Contact"
            onClick={() => onSetActiveMenuItem("item7")}
            className={`font-medium ${
              activeItem === "item7" ? "" : ""
            } hover:opacity-75`}
          >
            <button class="bg-white text-black rounded p-2 items-start w-24">
              Let's talk
            </button>
          </Link>
        </div>

        <div className=" sidebar sm:hidden flex justify-end flex-1 items-center z-10">
          <img
            src={toggle ? Close : Menu}
            alt="menu"
            className="w-[28px] object-contain cursor-pointer"
            onClick={() => setToggle((prev) => !prev)}
          />
          <div
            className={` ${
              toggle ? "flex" : "hidden"
            } p-6 bg-gradient sidebar absolute top-12 right-0 mx-4 my-2 min-w-[140px] rounded-xl `}
          >
            <ul className=" text-white list-none flex-col flex justify-end items-center flex-1 bg-transparent ">
              <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                  smooth={true}
                  duration={300}
                  to="Home"
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px]  `}
                >
                  Home
                </Link>
              </li>
              <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                  smooth={true}
                  duration={300}
                  to="About"
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px]  `}
                >
                  About
                </Link>
              </li>
              <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                  smooth={true}
                  duration={300}
                  to="Skills"
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px]  `}
                >
                  Skills
                </Link>
              </li>
              {/* <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                   smooth={true}
                   duration={300}
                   to={nav.id}
                   key={nav.id}
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px] `}
                >
                  Projects
                </Link>
              </li> */}

              <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                  smooth={true}
                  duration={300}
                  to="Certificate"
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px]  `}
                >
                  Certificate
                </Link>
              </li>
              <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                  smooth={true}
                  duration={300}
                  to="Testimonials"
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px] `}
                >
                  Testimonials
                </Link>
              </li>
              <li
                className={`font-poppins font-normal z-10 cursor-pointer text-[16px] bg-transparent  mb-10`}
              >
                <Link
                  smooth={true}
                  duration={300}
                  to="Contact"
                  className={`font-poppins bg-transparent  font-normal cursor-pointer text-[16px] `}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
